<script>
import { mapActions } from 'vuex'
export default {
  name: 'SolutionsConfig',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Tabs: () => import('@/components/general/Tabs'),
    Datatable: () => import('@/components/general/Datatable'),
    InputField: () => import('@/components/general/InputField'),
    Action: () => import('@/components/general/Action')
  },

  data () {
    return {
      tabLinks: [
        {
          location: { name: 'solutions.index' },
          text: this.$t('solutions.tabs:link.1')
        },
        {
          location: { name: 'solutions.configs' },
          text: this.$t('solutions.tabs:link.2')
        }
      ],

      rules: []
    }
  },

  created () {
    this.getEndRules()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsEndRules',
      'attemptUpdateSolutionsEndRules'
    ]),

    getEndRules () {
      this.setFetching(true)

      this.attemptGetSolutionsEndRules().then(res => {
        this.rules = res.data.configurations.solutionsTypes
      }).finally(() => {
        this.setFetching(false)
      })
    },

    updateEndRules () {
      const form = {}

      form.configurations = {}
      form.configurations.solutionsTypes = {}

      this.rules.forEach(rule => {
        form.configurations.solutionsTypes[rule.alias] = Number(rule.minimumRequiredConclusion)
      })

      this.attemptUpdateSolutionsEndRules(form).then(() => {
        this.setFeedback({ message: this.$t('solutions.config:feedback') })
      }).finally(() => {
        this.getEndRules()
      })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('solutions:header.title')"
      :description="$t('solutions:header.description')"
      background="/assets/images/themes/default/png/solutions_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :index-active="1"
        :links="tabLinks"
      />
    </ContentHeader>
    <div class="center solutions-config">
      <h3>{{ $t('solutions.config:title') }}</h3>
      <span>{{ $t('solutions.config:subtitle') }}</span>
      <Datatable :items="rules">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('solutions.config:datatable.header.1') }}
            </th>
            <th class="th text-center">
              {{ $t('solutions.config:datatable.header.2') }}
            </th>
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td input-range">
              <InputField
                v-model="props.item.minimumRequiredConclusion"
                :label="props.item.name"
                class="slider"
                type="range"
                :min="0"
                :max="100"
              />
            </td>
            <td
              class="td"
              width="80"
            >
              <InputField
                v-model="props.item.minimumRequiredConclusion"
                :max="100"
                :min="0"
                type="number"
                has-percent
              />
            </td>
          </tr>
        </template>
      </Datatable>
      <Action
        slot="button"
        primary
        fixed-width
        type="button"
        :text="$t('global:save')"
        @click="updateEndRules"
      />
    </div>
  </div>
</template>

<style>
.solutions-config {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
}
.solutions-config h3 {
  color: rgba(0,0,0,0.5);
  font-family: 'Quicksand';
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2.2em;
  height: auto;
}
.solutions-config span {
  font-size: 1.25em;
}
.solutions-config .datatable-wrapper {
  padding-top: 35px;
}
.solutions-config table .td {
  border: none;
}
.td.input-range {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
}
.input-range .form-input{
  padding: 8px 0;
}
.td .form-item.has-value.has-floating-label .form-label {
  font-size: 1.5em;
  text-transform: capitalize;
  font-weight: 500;
}
.solutions-config .btn{
  align-self: center;
  margin-top: 15px;
}
</style>
